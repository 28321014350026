import React from 'react'
import {
  DataPoint,
  HStack,
  TableWidget,
  Token,
  VStack,
  Text,
  Progress,
  Icon,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { fetchGoalGraph, getGoalCommentsAPI } from '@src/api/goals'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { KpiInterface } from '@src/interfaces/kpis'
import { TargetsWidget } from '../Widgets/Targets/TargetsWidget'
import { RoadmapsWidget } from '../Widgets/Roadmaps/RoadmapsWidget'
import { getTargets } from '../../common/utils'
import { GoalApprovalFlow } from './GoalApprovalFlow'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { roundFloat } from '@src/utils/numbers'
import CommentsSection from '@src/features/Comments/CommentsSection'

export const GoalsPreviewLeftSide = ({
  cycle,
  availableCycles,
  onCycleChanged,
}: {
  cycle: ReviewCyclesInterface
  availableCycles: ReviewCyclesInterface[]
  onCycleChanged: (cycleId: number | string) => void
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  const onCycleChange = (filter: FilterByInterface) => {
    const cycleId = filter.filters[0].id
    onCycleChanged(cycleId)
  }
  const filter = [
    {
      columnName: 'review_cycle',
      filters: [{ id: cycle?.id, name: cycle?.name }],
    },
  ]

  const currentTarget = values.kpis.find(
    kpi => getTargets(kpi)[0]?.review_cycle?.id === cycle.id,
  )

  const currentRoadmaps = values.roadmaps.filter(
    roadmap => roadmap.review_cycle.id === cycle.id,
  )

  const progress = roundFloat((values?.calibrated_progress ?? values?.progress) * 100, 2)

  return (
    <VStack space="s-24">
      <GoalApprovalFlow id={values.id} approvalStatus={values.approval_status.id} />
      <TableWidget>
        <TableWidget.Info>
          <DataPoint>
            <DataPoint.Value color={Token.color.greyTone50}>
              <HStack space="s-8" align="center">
                <Text
                  use="h5"
                  color={getPercentColor(progress)}
                  aria-labelledby="overall_progress_label"
                >
                  {progress}%
                </Text>
                <GraphIconChart
                  id={values.object_id}
                  vertical="right"
                  fetchData={fetchGoalGraph}
                >
                  <Icon
                    name="BarChart"
                    size={16}
                    style={{ cursor: 'pointer' }}
                    color={Token.color.greyTone50}
                  />
                </GraphIconChart>
              </HStack>
            </DataPoint.Value>
            <DataPoint.Label id="overall_progress_label">
              Overall Progress
            </DataPoint.Label>
          </DataPoint>

          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={onCycleChange}
            columnName="review_cycle"
            filter={filter}
            selector={() =>
              Promise.resolve({
                options: availableCycles as unknown as OptionInterface[],
              })
            }
            filterInputType={FilterSelectType.SingleSelect}
          />
        </TableWidget.Info>
        <TableWidget.Table>
          {currentTarget ? <GoalTargetProgress kpi={currentTarget} /> : null}
        </TableWidget.Table>
      </TableWidget>
      <TargetsWidget viewMode />
      {currentRoadmaps && currentRoadmaps.length ? <RoadmapsWidget viewMode /> : null}
      <GoalsCommentsSection goalId={values.id} />
    </VStack>
  )
}

function GoalTargetProgress({ kpi }: { kpi: KpiInterface }) {
  return (
    <Progress value={kpi.calibrated_progress || 0}>
      <Progress.Label>{kpi.initial_value}</Progress.Label>
      <Progress.Label>{kpi.target}</Progress.Label>
    </Progress>
  )
}

function GoalsCommentsSection({ goalId }: { goalId: number }) {
  const api = getGoalCommentsAPI(goalId)

  return <CommentsSection api={api} />
}
