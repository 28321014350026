import { Flex, Icon, Text } from '@revolut/ui-kit'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { PercentageWrapper } from '@src/components/CommonSC/TableStatistics'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { fetchGoalGraph } from '@src/api/goals'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { getStatusColor } from '@src/components/CommonSC/General'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { EditableColumnInterface } from '@src/components/Table/EditableTable/EditableTable'
import { roundFloat } from '@src/utils/numbers'

const isChildRow = (data: GoalsInterface | KpiInterface): data is KpiInterface => {
  return 'update_type' in data
}

export const goalsNameColumn: ColumnInterface<GoalsInterface> = {
  title: 'Goals & targets',
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'name',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.text,
}

export const goalsInitialValueColumn: ColumnInterface<GoalsInterface> = {
  title: 'Initial',
  type: CellTypes.insert,
  idPoint: 'initial_value',
  dataPoint: 'initial_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return data.initial_value
    }
    return ''
  },
}

export const goalsCurrentValueColumn: ColumnInterface<GoalsInterface> = {
  title: 'Current',
  type: CellTypes.insert,
  idPoint: 'current_progress',
  dataPoint: 'current_progress',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return data.current_progress
    }
    return ''
  },
}

export const goalsTargetColumns: ColumnInterface<GoalsInterface> = {
  title: 'Target',
  type: CellTypes.insert,
  idPoint: 'target',
  dataPoint: 'target',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return data.target
    }
    return ''
  },
}

export const goalsStrategyColumn: ColumnInterface<GoalsInterface> = {
  title: 'Strategy',
  type: CellTypes.insert,
  idPoint: 'kpi_goal',
  dataPoint: 'kpi_goal',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return capitalize(data.kpi_goal || '')
    }
    return ''
  },
}

export const goalsTypeColumn: ColumnInterface<GoalsInterface> = {
  title: 'Type',
  type: CellTypes.insert,
  idPoint: 'update_type',
  dataPoint: 'update_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      switch (data.update_type) {
        case UpdateTypes.manual:
          return <Icon name="Profile" size={18} />
        case UpdateTypes.sql:
          return <Icon name="ArrowRepeat" size={18} />
        case UpdateTypes.roadmap:
          return <Icon name="JiraEpic|image" size={18} />
        default:
          return capitalize(data.update_type)
      }
    }
    return ''
  },
}

export const goalsStatusColumn: ColumnInterface<GoalsInterface> = {
  title: 'Status',
  type: CellTypes.insert,
  idPoint: 'status__id',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.goal_statuses,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return ''
    }
    if (data.approval_status.id !== ApprovalStatuses.Approved) {
      return (
        <Text color={getStatusColor(data.approval_status.id)}>
          {data.approval_status.name}
        </Text>
      )
    }

    return <Text color={getStatusColor(data.status.id)}>{data.status.name}</Text>
  },
}

export const goalsProgressColumn: ColumnInterface<GoalsInterface> = {
  title: 'Progress',
  type: CellTypes.insert,
  idPoint: 'performance',
  dataPoint: 'performance',
  sortKey: 'progress',
  filterKey: 'progress',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    const progress = isChildRow(data)
      ? data.performance
      : data.calibrated_progress ?? data.progress
    const fetchGraph = isChildRow(data) ? getKpiPerformanceGraph : fetchGoalGraph
    // todo: fix rendering graph icon
    return (
      <GraphIconChart id={data.id} fetchData={fetchGraph}>
        <Flex width={75} justifyContent="space-between">
          <PercentageWrapper>
            <ColoredPercent percent={progress * 100} />
            <Icon type="Graph" />
          </PercentageWrapper>
        </Flex>
      </GraphIconChart>
    )
  },
}

export const goalsOwnerColumn: ColumnInterface<GoalsInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner',
  dataPoint: 'owner',
  sortKey: 'owner__full_name',
  filterKey: 'owner_id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const goalsWeightColumn: ColumnInterface<GoalsInterface> = {
  title: 'Weight',
  type: CellTypes.insert,
  idPoint: 'weight',
  dataPoint: 'weight',
  sortKey: 'weight',
  filterKey: 'weight',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return ''
    }
    return typeof data.weight !== 'undefined' ? `${roundFloat(data.weight, 2)}%` : ''
  },
}

export const goalsInputWeightColumn: EditableColumnInterface<GoalsInterface> = {
  inputType: TableCellInputType.positiveFloat,
  suffix: '%',
  title: 'Weight',
  idPoint: 'weight',
  dataPoint: 'weight',
  sortKey: 'weight',
  filterKey: 'weight',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  isEditable: () => true,
  cleanZeroOnFocus: true,
}

export const goalsUnitColumn: ColumnInterface<GoalsInterface> = {
  title: 'Unit',
  type: CellTypes.insert,
  idPoint: 'unit',
  dataPoint: 'unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (isChildRow(data)) {
      return data.unit
    }
    return ''
  },
}
