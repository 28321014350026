import React, { useMemo } from 'react'
import { CounterWidget, CounterWidgetSkeleton } from '@revolut/ui-kit'
import {
  CandidatesPipelineViewType,
  useGetCandidatesPipelineView,
} from '@src/api/recruitment/candidates'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { InterviewRoundState } from '@src/interfaces/interviewTool'

const getCommonFilters = (specialisationId?: string | number) =>
  [
    specialisationId
      ? {
          columnName: 'active_interview_round__specialisation',
          filters: [{ id: specialisationId, name: String(specialisationId) }],
        }
      : null,
    {
      columnName: 'is_snoozed',
      filters: [
        {
          id: 'False',
          name: 'False',
        },
      ],
    },
  ].filter(Boolean)

const useCandidateCounts = (specialisationId?: string | number) => {
  const statsFilters = useMemo(() => {
    const commonFilters = getCommonFilters(specialisationId)
    return {
      active: [
        ...commonFilters,
        {
          columnName: 'active_interview_round__state',
          filters: [{ id: InterviewRoundState.active, name: InterviewRoundState.active }],
        },
      ],
      archived: [
        ...commonFilters,
        {
          columnName: 'active_interview_round__state',
          filters: [
            { id: InterviewRoundState.archived, name: InterviewRoundState.archived },
          ],
        },
      ],
    }
  }, [specialisationId])
  const { data: activeCandidatesStats, isLoading: loadingActiveCandidatesStats } =
    useGetCandidatesPipelineView(statsFilters.active)
  const { data: archivedCandidatesStats, isLoading: loadingArchivedCandidatesStats } =
    useGetCandidatesPipelineView(statsFilters.archived)
  const counts = useMemo(() => {
    const findTotal = ({ stage_type }: CandidatesPipelineViewType) =>
      stage_type === 'Total'
    const active = activeCandidatesStats?.find(findTotal)?.count ?? 0
    const rejected = archivedCandidatesStats?.find(findTotal)?.count ?? 0
    const total = active + rejected
    return {
      total,
      rejected,
      active,
    }
  }, [activeCandidatesStats, archivedCandidatesStats])
  return {
    counts,
    loading: loadingActiveCandidatesStats || loadingArchivedCandidatesStats,
  }
}

const CandidateCount = () => {
  const { values } = useLapeContext<RequisitionInterface>()
  const { loading, counts } = useCandidateCounts(values.specialisation.id)
  if (loading) {
    return (
      <CounterWidget>
        <CounterWidget.Column>
          <CounterWidgetSkeleton.Description />
          <CounterWidgetSkeleton.Subtitle />
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidgetSkeleton.Description />
          <CounterWidgetSkeleton.Subtitle />
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidgetSkeleton.Description />
          <CounterWidgetSkeleton.Subtitle />
        </CounterWidget.Column>
      </CounterWidget>
    )
  }
  return (
    <CounterWidget>
      <CounterWidget.Column>
        <CounterWidget.Description>{counts.total}</CounterWidget.Description>
        <CounterWidget.Subtitle textAlign="center">Total</CounterWidget.Subtitle>
      </CounterWidget.Column>
      <CounterWidget.Column>
        <CounterWidget.Description>{counts.active}</CounterWidget.Description>
        <CounterWidget.Subtitle textAlign="center">Active</CounterWidget.Subtitle>
      </CounterWidget.Column>
      <CounterWidget.Column>
        <CounterWidget.Description>{counts.rejected}</CounterWidget.Description>
        <CounterWidget.Subtitle textAlign="center">Rejected</CounterWidget.Subtitle>
      </CounterWidget.Column>
    </CounterWidget>
  )
}

export default CandidateCount
