import React, { useEffect, useState } from 'react'
import { ActionButton, Button, HStack, Header, Token } from '@revolut/ui-kit'
import { matchPath, useLocation } from 'react-router-dom'
import useResizeObserver from 'use-resize-observer'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { setupGuideConfig } from './SetupGuide/config'
import { SetupGuideSidebar } from './SetupGuide/SetupGuideSidebar'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'

interface OnboardingChecklistHeaderProps {
  title: React.ReactNode
  description?: React.ReactNode
  backUrl?: string
}

export const OnboardingChecklistHeader = ({
  title,
  description,
  backUrl,
}: OnboardingChecklistHeaderProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { pathname } = useLocation()

  const { ref, height: headerHeight } = useResizeObserver()

  useEffect(() => {
    if (headerHeight) {
      mainHeaderState.height = headerHeight
    }
  }, [headerHeight])

  const matchedSidebarConfig = setupGuideConfig.find(config =>
    config.paths.some(path => matchPath(pathname, path)),
  )

  return (
    <>
      <Header variant="item" ref={ref}>
        <Header.BackButton use={InternalLink} to={backUrl} aria-label="Back" />
        <Header.Title>{title}</Header.Title>
        <Header.Description>{description}</Header.Description>
        <Header.Actions>
          <HStack space="s-16" align="center">
            {matchedSidebarConfig ? (
              <ActionButton
                onClick={() => setSidebarOpen(state => !state)}
                useIcon="Cleaning"
              >
                Setup Guide
              </ActionButton>
            ) : null}
            <Button
              variant="text"
              useIcon="Cross"
              color={Token.color.foreground}
              use={InternalLink}
              to={ROUTES.MAIN}
            >
              Close
            </Button>
          </HStack>
        </Header.Actions>
      </Header>

      <SetupGuideSidebar
        open={!!(sidebarOpen && matchedSidebarConfig)}
        onClose={() => setSidebarOpen(false)}
        title={matchedSidebarConfig?.title}
        description={matchedSidebarConfig?.description}
        customContent={matchedSidebarConfig?.customContent}
        questionsAnswers={matchedSidebarConfig?.questionsAnswers}
      />
    </>
  )
}
