import {
  DataPoint,
  HStack,
  TableWidget,
  Token,
  Text,
  MoreBar,
  TextSkeleton,
  Icon,
  Checkbox,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { useTable } from '@src/components/Table/hooks'
import {
  GoalsStats,
  ManageGoalEntityDefinition,
  fetchGoalGraph,
  goalsListTableRequests,
  manageGoals,
} from '@src/api/goals'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React, { useEffect, useMemo, useState } from 'react'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalsCurrentValueColumn,
  goalsInitialValueColumn,
  goalsInputWeightColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsStrategyColumn,
  goalsTargetColumns,
  goalsTypeColumn,
  goalsUnitColumn,
  goalsWeightColumn,
  goalsStatusColumn,
} from '@src/constants/columns/goals'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { AddGoalAction } from './components/AddGoalAction'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BulkGoalApproveAction } from './components/BulkGoalApproveAction'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { GoalWeightMode, GoalsInterface } from '@src/interfaces/goals'
import LapeForm, { useLapeContext } from '../Form/LapeForm'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import LapeEditableTable from '@src/components/Table/EditableTable/LapeEditableTable'
import { EditableRowInterface } from '@src/components/Table/EditableTable/EditableTable'
import { InfoIconWithTooltip } from '@src/components/Icon/InfoIconWithTooltip'
import NewWarningMessage, {
  NewWarningTypes,
} from '@src/components/NewWarningMessage/NewWarningMessage'
import { captureException } from '@sentry/react'
import ConfirmationDialog from '../Popups/ConfirmationDialog'
import { roundFloat } from '@src/utils/numbers'

interface GoalsTableWidgetProps {
  initialFilters: FilterByInterface[]
}

export const GoalsTableWidget = ({ initialFilters }: GoalsTableWidgetProps) => {
  const { entity } = useOrgEntity()
  const [manageMode, setManageMode] = useState(false)
  const defaultWeightMode =
    (entity && 'goal_weight_mode' in entity.data && entity.data.goal_weight_mode?.id) ||
    'manual'
  const [weightMode, setWeightMode] = useState<GoalWeightMode>(defaultWeightMode)
  const table = useTable(goalsListTableRequests, initialFilters)
  const form = useLapeContext<GoalsWidgetFormValues>()
  const statusPopup = useStatusPopup()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const totalWeight = form.values.data.map(g => g.weight).reduce((a, b) => a + b, 0)

  useEffect(() => {
    form.reset({ data: table.data })
  }, [table.data])

  const goalsIdsToApprove = table.data
    .filter(({ approval_status }) => approval_status.id !== ApprovalStatuses.Approved)
    .map(({ id }) => ({ id }))

  const row = useMemo<
    EditableRowInterface<GoalsInterface> | RowInterface<GoalsInterface>
  >(
    () => ({
      linkToForm: manageMode
        ? undefined
        : (data, parentIndexes) => {
            const id =
              parentIndexes && parentIndexes.length
                ? table.data[parentIndexes[0]]?.id
                : data.id

            if (id) {
              const url = pathToUrl(
                ROUTES.FORMS.GOAL.PREVIEW,
                { id },
                filterSortPageIntoQuery(undefined, table.filterBy) as Record<
                  string,
                  string
                >,
              )
              navigateTo(url)
            }
          },
      noChildrenRequest: true,
      cells: [
        {
          ...goalsNameColumn,
          width: 300,
        },
        {
          ...goalsInitialValueColumn,
          width: 50,
        },
        {
          ...goalsCurrentValueColumn,
          width: 50,
        },
        {
          ...goalsTargetColumns,
          width: 50,
        },
        {
          ...goalsUnitColumn,
          width: 50,
        },
        {
          ...goalsStrategyColumn,
          width: 50,
        },
        {
          ...goalsTypeColumn,
          width: 50,
        },
        {
          ...(manageMode && weightMode === 'manual'
            ? goalsInputWeightColumn
            : goalsWeightColumn),
          width: 50,
        },
        {
          ...goalsStatusColumn,
          width: 100,
        },

        {
          ...goalsProgressColumn,
          width: 50,
        },
        {
          ...goalsOwnerColumn,
          width: 150,
        },
      ].filter(Boolean),
    }),
    [manageMode, table.data, weightMode],
  )

  const getManageGoalsEntityDefinition = (
    values: GoalsWidgetFormValues,
  ): ManageGoalEntityDefinition | null => {
    if (!entity) {
      return null
    }

    if (entity.type === EntityTypes.company) {
      return {
        is_company: true,
      }
    }
    if (values.data[0].content_type) {
      const contentType = values.data[0].content_type
      return { content_type: { id: contentType.id }, object_id: entity.data.id }
    }
    return null
  }

  const handleSubmit = async () => {
    const values = form.values
    const entityDefinition = getManageGoalsEntityDefinition(values)

    if (!entityDefinition) {
      captureException('Failed to determine entity definition for manage goals action')
      throw new Error('Please reload page and try again.')
    }

    try {
      await manageGoals({
        ...entityDefinition,
        goal_weight_mode: { id: weightMode },
        goals: values.data.map(({ id, weight }) => ({ id, weight })),
      })

      table.refresh()
      setManageMode(false)

      return values
    } catch (err) {
      captureException(err)

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to update weights</StatusPopup.Title>
          <StatusPopup.Description>
            Something went wrong, please try again later.
          </StatusPopup.Description>
        </StatusPopup>,
      )

      throw err
    }
  }

  const autoDistributeWeights = () => {
    let sum = 0
    const count = table.data.length
    const roundedWeight = roundFloat(100 / count, 2)
    const minIncrement = 0.01
    const diff = roundFloat(100 - roundedWeight * count, 2) / minIncrement

    form.reset({
      data: table.data.map((goal, index) => {
        if (index < diff) {
          const weight = roundedWeight + minIncrement

          sum += weight

          return {
            ...goal,
            weight,
          }
        }
        if (goal === table.data.at(-1)) {
          return {
            ...goal,
            weight: roundFloat(100 - sum, 2),
          }
        }

        sum += roundedWeight

        return {
          ...goal,
          weight: roundedWeight,
        }
      }),
    })

    setWeightMode('automatic')
  }

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <DataPoint>
            <DataPoint.Value color={Token.color.greyTone50}>
              {entity && table.stats?.overall_progress !== undefined ? (
                <HStack space="s-8" align="center">
                  <Text use="h5">{Math.round(table.stats.overall_progress * 100)}%</Text>
                  <GraphIconChart
                    id={entity.data.id}
                    vertical="right"
                    fetchData={fetchGoalGraph}
                  >
                    <Icon
                      name="BarChart"
                      size={16}
                      style={{ cursor: 'pointer ' }}
                      color={Token.color.greyTone50}
                    />
                  </GraphIconChart>
                </HStack>
              ) : (
                <TextSkeleton />
              )}
            </DataPoint.Value>
            <DataPoint.Label>Overall Progress</DataPoint.Label>
          </DataPoint>
          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={table.onFilterChange}
            columnName="cycle__id"
            filter={table.filterBy}
            selector={selectorKeys.review_cycles}
            filterInputType={FilterSelectType.SingleSelect}
          />
        </TableWidget.Info>
        <TableWidget.Search>
          <SearchTable
            placeholder="Search by name"
            onFilter={table.onFilterChange}
            ml={0}
          />
        </TableWidget.Search>
        <TableWidget.Actions>
          <HStack space="s-12" align="center">
            <MoreBar>
              <BulkGoalApproveAction
                goals={goalsIdsToApprove}
                onApproved={table.refresh}
                title={
                  goalsIdsToApprove.length === table.data.length
                    ? 'Approve all goals'
                    : undefined
                }
              />
              <AddGoalAction />
              <MoreBar.Action
                useIcon={manageMode ? '16/SwitchOn' : '16/SwitchOff'}
                onClick={() => setManageMode(!manageMode)}
              >
                Manage goals
              </MoreBar.Action>
            </MoreBar>
            {manageMode && (
              <Checkbox
                value={weightMode}
                onChange={() => {
                  const newMode = weightMode === 'automatic' ? 'manual' : 'automatic'

                  if (newMode === 'automatic') {
                    setConfirmOpen(true)
                  } else {
                    setWeightMode('manual')
                  }
                }}
                checked={weightMode === 'automatic'}
              >
                <HStack space="s-8" align="center">
                  <Text>Auto distribute weights</Text>
                  <InfoIconWithTooltip
                    color={Token.color.greyTone50}
                    size={16}
                    content="This will equally distribute the goals weights and overwrite the current values."
                  />
                </HStack>
              </Checkbox>
            )}
          </HStack>
        </TableWidget.Actions>
        <TableWidget.Table>
          {manageMode ? (
            <LapeEditableTable<GoalsInterface>
              childreOpenByDefault={false}
              dataFieldName="data"
              expandableType="chevron"
              name={TableNames.Goals}
              dataType="Goal"
              disableFilters={manageMode}
              {...table}
              initialData={table.data}
              row={row}
              replaceOnInitialDataChange
              hiddenCells={{
                [goalsInitialValueColumn.idPoint]: true,
                [goalsCurrentValueColumn.idPoint]: true,
                [goalsTargetColumns.idPoint]: true,
                [goalsUnitColumn.idPoint]: true,
                [goalsStrategyColumn.idPoint]: true,
                [goalsTypeColumn.idPoint]: true,
              }}
            />
          ) : (
            <AdjustableTable<GoalsInterface, GoalsStats>
              childreOpenByDefault
              expandableType="chevron"
              name={TableNames.Goals}
              dataType="Goal"
              {...table}
              row={row as RowInterface<GoalsInterface>}
            />
          )}

          {form.dirty && manageMode && totalWeight !== 100 ? (
            <NewWarningMessage
              mt="s-16"
              type={
                totalWeight && totalWeight > 100
                  ? NewWarningTypes.error
                  : NewWarningTypes.warning
              }
            >
              The sum of all weights should be 100%, currently {totalWeight || 0}%
            </NewWarningMessage>
          ) : null}
        </TableWidget.Table>
      </TableWidget>
      {manageMode && (
        <PageActions mt="s-16">
          <NewSaveButtonWithPopup
            disabled={totalWeight !== 100}
            onClick={handleSubmit}
            successText="Goal weights have been updated"
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      )}
      <ConfirmationDialog
        variant="compact"
        open={confirmOpen}
        label="Confirm auto distribution of weights"
        body="This will equally distribute the goals weights and overwrite the current values."
        yesMessage="Continue"
        noMessage="Cancel"
        onClose={() => setConfirmOpen(false)}
        onReject={() => setConfirmOpen(false)}
        onConfirm={() => {
          autoDistributeWeights()
          setConfirmOpen(false)
        }}
      />
    </>
  )
}

interface GoalsWidgetFormValues {
  data: GoalsInterface[]
}

const initialFormData = { data: [] }

export const GoalsTableFormWidget = ({
  initialFilters,
}: {
  initialFilters: FilterByInterface[]
}) => {
  return (
    <LapeForm
      disableValidation
      onSubmit={() => Promise.resolve({})}
      initialValues={initialFormData}
    >
      <GoalsTableWidget initialFilters={initialFilters} />
    </LapeForm>
  )
}
