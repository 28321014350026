import React, { useState } from 'react'
import { Group, Icon, Separator, Token, VStack } from '@revolut/ui-kit'
import {
  HeaderItem,
  HeaderMenu,
} from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/HeaderItem'
import ChangeJobPosting from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/ChangeJobPosting'

type StartHiringProps = {
  onRefresh: () => Promise<void>
}

const StartHiring = ({ onRefresh }: StartHiringProps) => {
  const [openJobPostingSidebar, setOpenJobPostingSidebar] = useState(false)
  return (
    <>
      <Group>
        <HeaderItem
          side={
            <HeaderMenu
              label="Start hiring"
              useIcon="Rocket"
              onConnectJobPosting={() => {
                setOpenJobPostingSidebar(true)
              }}
            />
          }
        />
        <Separator />
        <VStack gap="s-8" py="s-32" align="center">
          <Icon name="Info" color={Token.color.greyTone50} />
          <HeaderMenu
            label="Start hiring"
            onConnectJobPosting={() => {
              setOpenJobPostingSidebar(true)
            }}
          />
        </VStack>
      </Group>
      {openJobPostingSidebar && (
        <ChangeJobPosting
          onClose={() => {
            setOpenJobPostingSidebar(false)
          }}
          onRefresh={onRefresh}
        />
      )}
    </>
  )
}

export default StartHiring
