import React, { useState } from 'react'
import {
  InterviewCancellingStatuses,
  InterviewPendingSchedulingStatuses,
  InterviewReschedulingStatuses,
  InterviewStageWithoutRoundInterface,
  InterviewStatuses,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import {
  Color,
  DropdownItemProps,
  MoreBar,
  StatusPopup,
  Text,
  useStatusPopup,
} from '@revolut/ui-kit'
import GenerateLinkPopup from '@src/pages/Forms/Candidate/InterviewProgress/components/Popups/GenerateLinkPopup'
import {
  changeStage,
  skipInterview,
  unskipInterview,
} from '@src/pages/Forms/Candidate/utils'
import RescheduleInterviewButton from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/RescheduleInterviewButton'
import StagesTableActions from '@src/pages/Forms/Candidate/InterviewProgress/components/StagesTableActions/StagesTableActions'
import CancelInterviewButton from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/CancelInterviewButton'
import AddFeedbackButton from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/AddFeedbackButton'
import MenuAction, { MenuActionType } from '@components/MenuAction/MenuAction'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetLatestOffer } from '@src/api/offerCreation'
import {
  useGetCandidateSettings,
  useGetDocumentsSettings,
  useGetOfferSettings,
} from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useGetCandidate } from '@src/api/recruitment/interviews'
import {
  clearCVScreeningSession,
  navigateToCVScreening,
  setCVScreeningSession,
} from '@src/pages/Forms/CVScreening/utils'

type Props = {
  stage: InterviewStageWithoutRoundInterface
  canAddFeedback: boolean
  stages?: InterviewStageWithoutRoundInterface[]
  roundId: number
  canCancel: boolean
  onRefresh?: () => void
  currentStageId?: number
  onOpenSidebar?: (
    data: InterviewStageWithoutRoundInterface,
    mode?: ScheduleSidebarModeType,
  ) => void
  menuType: MenuActionType
  children?: React.ReactNode
  canViewEditOffer: boolean
  candidateId: number
}

const StageActions = ({
  canAddFeedback,
  canViewEditOffer,
  stage,
  stages,
  roundId,
  onRefresh,
  currentStageId,
  onOpenSidebar,
  menuType,
  canCancel,
  children,
  candidateId,
}: Props) => {
  const statusPopup = useStatusPopup()
  const [isOpen, setOpen] = useState(false)
  const [isLinkPopupOpen, setLinkPopupOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { data: documentsSettings } = useGetDocumentsSettings()
  const { data: offerSettings } = useGetOfferSettings()
  const { data: candidateSettings } = useGetCandidateSettings()
  const isCommercial = useIsCommercial()
  const offersEnabled =
    (isCommercial ? true : documentsSettings?.enable_docusign_integration) &&
    canViewEditOffer
  const { data: latestOffer } = useGetLatestOffer(
    stage.interview_type === 'offer' && offersEnabled ? roundId : null,
  )
  const { data: candidate } = useGetCandidate(canViewEditOffer ? candidateId : null)

  const itemProps: DropdownItemProps = {
    disabled: loading,
    use: 'button',
  }

  const linkHandler = () => {
    setOpen(false)
    setLinkPopupOpen(true)
  }

  const skipInterviewHandler = async () => {
    setLoading(true)
    setOpen(false)

    try {
      await skipInterview(roundId, stage.id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Interview scheduling skipped</StatusPopup.Title>
        </StatusPopup>,
      )
      onRefresh?.()
    } finally {
      setLoading(false)
    }
  }

  const changeStageHandler = async () => {
    setLoading(true)
    setOpen(false)

    try {
      const resp = await changeStage(roundId, stage.id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>
            Changed stage to {resp.latest_interview_stage?.title}
          </StatusPopup.Title>
        </StatusPopup>,
      )
      onRefresh?.()
    } finally {
      setLoading(false)
    }
  }

  const unskipInterviewHandler = async () => {
    setLoading(true)
    setOpen(false)

    try {
      await unskipInterview(roundId, stage.id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Interview scheduling unskipped</StatusPopup.Title>
        </StatusPopup>,
      )

      onRefresh?.()
    } finally {
      setLoading(false)
    }
  }

  const getActions = () => {
    const actions: React.ReactNode[] = []

    const generateScorecardAction = () => {
      if (canAddFeedback) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: linkHandler,
            }}
            key="Generate scorecard"
          >
            Generate scorecard
          </MenuAction>,
        )
      }
    }

    const addFeedbackAction = () => {
      if (canAddFeedback) {
        actions.push(
          <AddFeedbackButton
            menuType={menuType}
            stageId={stage.id}
            loading={loading}
            setLoading={setLoading}
            onProceed={() => {
              setOpen(false)
              onRefresh?.()
            }}
            edit={stage.scheduling_status === 'feedback_submitted'}
            key="add"
          />,
        )
      }
    }

    const cvScreeningAction = () => {
      if (stage.id === currentStageId) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: () => {
                clearCVScreeningSession()
                setCVScreeningSession({ candidateIds: [candidateId] })
                navigateToCVScreening(candidateId)
              },
            }}
            key="CV screening"
          >
            Screen CV
          </MenuAction>,
        )
      }
    }

    const sendTestInviteAction = () => {
      if (
        stage.scheduling_status === 'test_not_sent' ||
        stage.scheduling_status === 'test_sent'
      ) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: () => {
                navigateTo(
                  pathToUrl(ROUTES.FORMS.SEND_ONLINE_TEST, {
                    candidateId,
                    roundId,
                    stageId: stage.id,
                  }),
                )
              },
            }}
            key="Send invite"
          >
            {stage.scheduling_status === 'test_not_sent'
              ? 'Send invite'
              : 'Re-send invite'}
          </MenuAction>,
        )
      }
    }

    const moveStageAction = () => {
      if (stage.id !== currentStageId) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: changeStageHandler,
            }}
            key="Move to this stage"
          >
            Move to this stage
          </MenuAction>,
        )
      }
    }

    const createOfferAction = () => {
      if (latestOffer) {
        return
      }

      const label = offerSettings?.enable_offer_templates ? 'Create offer' : 'Hire'

      actions.push(
        <MenuAction
          menuType={menuType}
          props={{
            ...itemProps,
            'aria-disabled': !candidate?.phone,
            onClick: () => {
              if (candidate?.phone) {
                navigateTo(
                  pathToUrl(ROUTES.FORMS.OFFER_CREATION.GENERAL, {
                    candidateId,
                  }),
                )
              }
            },
          }}
          key={label}
        >
          {label}
          {!candidate?.phone && (
            <Text use="div" color={Color.RED}>
              Candidate has no phone number
            </Text>
          )}
        </MenuAction>,
      )
    }

    const editOfferAction = () => {
      if (!latestOffer) {
        return
      }

      actions.push(
        <MenuAction
          menuType={menuType}
          props={{
            ...itemProps,
            onClick: () =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.OFFER_CREATION.GENERAL, {
                  candidateId,
                  id: latestOffer.id,
                }),
              ),
          }}
          key="Edit offer"
        >
          Edit offer
        </MenuAction>,
      )
    }

    const viewOfferAction = () => {
      if (!latestOffer) {
        return
      }

      actions.push(
        <MenuAction
          menuType={menuType}
          props={{
            ...itemProps,
            onClick: () =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.OFFER_CREATION.PREVIEW, {
                  candidateId,
                  id: latestOffer.id,
                }),
              ),
          }}
          key="View offer"
        >
          View offer
        </MenuAction>,
      )
    }

    const openTestReportAction = () => {
      if (
        stage.scheduling_status === 'test_completed' &&
        stage.online_test_result?.test_report?.url
      ) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: () => {
                window.open(stage.online_test_result!.test_report!.url!, '_blank')
              },
            }}
            key="Open report"
          >
            Open report
          </MenuAction>,
        )
      }
    }

    const scheduleInterviewAction = () => {
      if (
        candidateSettings?.enable_scheduling &&
        InterviewPendingSchedulingStatuses.includes(stage.scheduling_status)
      ) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: () => {
                onOpenSidebar?.(stage, 'scheduling')
                setOpen(false)
              },
            }}
            key="Schedule interview"
          >
            Schedule interview
          </MenuAction>,
        )
      }
    }

    const undoSkipInterviewAction = () => {
      if (
        stage.scheduling_status === InterviewStatuses.awaiting_feedback &&
        stage.skip_scheduling
      ) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: unskipInterviewHandler,
            }}
            key="Undo skipping interview"
          >
            Undo skipping interview
          </MenuAction>,
        )
      }
    }

    const skipInterviewAction = () => {
      if (stage.scheduling_status === InterviewStatuses.pending_scheduling) {
        actions.push(
          <MenuAction
            menuType={menuType}
            props={{
              ...itemProps,
              onClick: skipInterviewHandler,
            }}
            key="Skip interview"
          >
            Skip interview
          </MenuAction>,
        )
      }
    }

    const rescheduleInterviewAction = () => {
      if (
        candidateSettings?.enable_scheduling &&
        InterviewReschedulingStatuses.includes(stage.scheduling_status)
      ) {
        actions.push(
          <RescheduleInterviewButton
            onProceed={() => {
              onOpenSidebar?.(stage, 'rescheduling')
              setOpen(false)
            }}
            disabled={itemProps.disabled}
            menuType={menuType}
            key="rescheduling"
          />,
        )
      }
    }

    const cancelInterviewAction = () => {
      if (
        candidateSettings?.enable_scheduling &&
        InterviewCancellingStatuses.includes(stage.scheduling_status) &&
        canCancel
      ) {
        actions.push(
          <CancelInterviewButton
            menuType={menuType}
            roundId={roundId}
            stageId={stage.id}
            onProceed={() => {
              onRefresh?.()
            }}
            key="cancel"
          />,
        )
      }
    }

    switch (stage.interview_type) {
      case 'cv_screening': {
        cvScreeningAction()
        moveStageAction()
        break
      }

      case 'online_test': {
        sendTestInviteAction()
        openTestReportAction()
        moveStageAction()
        break
      }

      case 'hiring_panel': {
        generateScorecardAction()
        addFeedbackAction()
        moveStageAction()
        break
      }

      case 'offer': {
        if (offersEnabled) {
          createOfferAction()

          if (offerSettings?.enable_offer_templates) {
            editOfferAction()
            viewOfferAction()
          }
        }

        moveStageAction()
        break
      }

      default: {
        generateScorecardAction()
        addFeedbackAction()
        scheduleInterviewAction()
        skipInterviewAction()
        undoSkipInterviewAction()
        rescheduleInterviewAction()
        cancelInterviewAction()
        moveStageAction()
      }
    }
    return actions
  }

  const actions = getActions()

  return (
    <>
      {menuType !== 'dropdown' ? (
        <MoreBar>
          {actions}
          {children}
        </MoreBar>
      ) : (
        <StagesTableActions isOpen={isOpen} setOpen={setOpen} loading={loading}>
          {actions}
          {children}
        </StagesTableActions>
      )}

      {stages && (
        <GenerateLinkPopup
          data={stages}
          roundId={roundId}
          isOpen={isLinkPopupOpen}
          onRefresh={onRefresh}
          onClose={() => setLinkPopupOpen(false)}
          initialStageId={stage.id}
          currentStageId={currentStageId}
        />
      )}
    </>
  )
}

export default StageActions
