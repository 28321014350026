import React, { useEffect, useMemo, useState } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { useLocation, useParams } from 'react-router-dom'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import {
  Absolute,
  ActionButton,
  ActionMenu,
  Bar,
  Box,
  Flex,
  HStack,
  Icon,
  MoreBarSkeleton,
  Relative,
  Separator,
  StatusPopup,
  Text,
  Toast,
  Token,
  useDropdown,
  useStatusPopup,
  useToast,
} from '@revolut/ui-kit'
import CVPreview from '@src/pages/Forms/CVScreening/CVScreeningDocument'
import { navigateTo } from '@src/actions/RouterActions'
import CVScreeningError from '@src/pages/Forms/CVScreening/CVScreeningError'
import { pathToUrl } from '@src/utils/router'
import {
  getLinkedinUrl,
  hasEmailNonPrefilledPlaceholders,
  useFetchMultipleCandidates,
} from '@src/utils/hiring'
import DocumentSkeleton from '@components/Skeletons/DocumentSkeleton'
import CVScreeningHeader from '@src/pages/Forms/CVScreening/CVScreeningHeader'
import CVScreeningSide from '@src/pages/Forms/CVScreening/CVScreeningSide'
import {
  archiveInterviews,
  changeInterviewStage,
  skipCVScreening,
} from '@src/api/recruitment/interviews'
import {
  CandidateActiveActionType,
  InterviewRecruiterInterface,
  InterviewResult,
  InterviewRoundInterface,
  InterviewShowScheduleSidebarStatuses,
  InterviewStageInterface,
} from '@src/interfaces/interviewTool'
import {
  sendCandidateEmail,
  useGetCandidateFormsApplicationSections,
} from '@src/api/hiringProcess'
import { useGetCandidateSettings, useGetHiringProcessSettings } from '@src/api/settings'
import { generateAndPatchResultScorecard } from '@src/api/recruitment/interviewFeedback'
import * as Sentry from '@sentry/react'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { CVScreeningArchive } from '@src/pages/Forms/CVScreening/actions/CVScreeningArchive'
import { CVScreeningApplicationQuestions } from '@src/pages/Forms/CVScreening/actions/CVScreeningApplicationQuestions'
import { CVScreeningTimeline } from '@src/pages/Forms/CVScreening/actions/CVScreeningTimeline'
import CVScreeningSendOnlineTest from '@src/pages/Forms/CVScreening/actions/CVScreeningSendOnlineTest'
import { useCVScreeningActionsTooltips } from '@src/pages/Forms/CVScreening/hooks'
import { getUpdatedArchivingData } from '@components/ArchivingCandidateSidebar/common'
import { getMessageFromError } from '@src/store/notifications/actions'
import {
  KeyboardShortcuts,
  navigateToCVScreening,
} from '@src/pages/Forms/CVScreening/utils'
import {
  getCVScreeningSession,
  setCVScreeningSession,
} from '@src/pages/Forms/CVScreening/utils'
import { ChangeStagePopup } from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/ChangeStagePopup'
import { CVScreeningChangeRecruiter } from '@src/pages/Forms/CVScreening/actions/CVScreeningChangeRecruiter'
import CVScreeningChangeJobPosting from '@src/pages/Forms/CVScreening/actions/CVScreeningChangeJobPosting'
import { IdAndName } from '@src/interfaces'
import { CvScreeningResultPopup } from '@src/pages/Forms/CVScreening/CVScreeningResultPopup'
import { differenceInSeconds } from 'date-fns'
import usePreventUserClose from '@src/hooks/usePreventUserClose'
import ScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduleSidebar'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'

type CVScreeningActionType =
  | 'schedule'
  | 'sendEmail'
  | 'archive'
  | 'applicationQuestions'
  | 'assignDifferentRecruiter'
  | 'assignDifferentJobPosting'
  | 'changeStage'
  | 'timeline'
  | 'sendOnlineTest'

interface ActionInterface extends Omit<CandidateActiveActionType, 'type'> {
  type: CVScreeningActionType
}

const CvScreening = () => {
  const statusPopup = useStatusPopup()
  const toast = useToast()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [pendingActions, setPendingActions] = useState(false)
  const params = useParams<{ id: string }>()
  const user = useSelector(selectUser)
  const [action, setAction] = useState<ActionInterface>()
  const [showResultPopup, setShowResultPopup] = useState(false)
  const [startTime, setStartTime] = useState<Date | null>(null)
  const session = getCVScreeningSession()

  // to avoid creating a new array each render
  const candidateIds = useMemo(
    () => session.candidateIds || [params.id],
    [session.candidateIds?.join(','), params.id],
  )
  const currentCandidateIndex = candidateIds.findIndex(id => id === params.id)

  const { data: candidateSettings } = useGetCandidateSettings()
  const { candidates, refresh } = useFetchMultipleCandidates(params.id, candidateIds)
  const candidateDataLoading = !candidates.get(params.id)?.loaded
  const candidate = candidates.get(params.id)?.candidate
  const round = candidates.get(params.id)?.round
  const stages = candidates.get(params.id)?.stages || []
  const error = candidates.get(params.id)?.error

  const { data: hiringProcessSettings } = useGetHiringProcessSettings()
  const { applicationFormSections, isLoading: isApplicationFormSectionsLoading } =
    useGetCandidateFormsApplicationSections(candidate?.id)

  const dropdown = useDropdown()

  useSetDocumentTitle(candidate?.full_name, 'CV Screening')

  const currentStageId = candidate?.active_interview_round?.latest_interview_stage?.id

  const currentStageIdx = stages.findIndex(stage => stage.id === currentStageId)
  const nextStage = stages[currentStageIdx + 1] as InterviewStageInterface | undefined

  const schedulingDisabled = !candidateSettings?.enable_scheduling

  const sendOnlineTestDisabled = !nextStage?.id || !round?.id || !candidate?.id

  const screeningError =
    !loading &&
    (round?.latest_interview_stage?.interview_type !== 'cv_screening' ||
      round?.archived_reason ||
      error)

  const linkedinUrl = getLinkedinUrl(candidate?.links)

  const tooltips = useCVScreeningActionsTooltips({
    linkedin: { disabled: !linkedinUrl },
    applicationQuestions: {
      disabled: !applicationFormSections.length,
    },
    scheduling: {
      disabled: schedulingDisabled,
    },
    sendOnlineTest: {
      disabled: nextStage?.interview_type !== 'online_test',
    },
  })

  usePreventUserClose(true)

  useEffect(() => {
    setLoading(candidateDataLoading)
  }, [candidateDataLoading])

  useEffect(() => {
    setStartTime(new Date())
  }, [params.id])

  const showSuccessToast = (msg: React.ReactNode) => {
    if (!candidate) {
      return
    }

    toast.show(
      <Toast>
        <Toast.Indicator>
          <Icon name="CheckSuccess" color={Token.color.success} />
        </Toast.Indicator>
        <Toast.Label>
          {candidate.full_name}: {msg}
        </Toast.Label>
      </Toast>,
      'short',
    )
  }

  const moveToNextCandidate = () => {
    if (!session.candidateIds?.length || session.candidateIds?.length === 1) {
      navigateTo(
        session.referrerPath ||
          pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: params.id }),
      )
      return
    }

    const screenedCandidateIds = candidate?.id
      ? [...(session.screenedCandidateIds || []), candidate.id]
      : session.screenedCandidateIds || []

    if (startTime) {
      const timeTakenInSeconds = differenceInSeconds(new Date(), startTime)
      setCVScreeningSession({
        duration: (session.duration || 0) + timeTakenInSeconds,
        screenedCandidateIds,
      })
    }

    if (candidateIds.length && currentCandidateIndex < candidateIds.length - 1) {
      navigateToCVScreening(candidateIds[currentCandidateIndex + 1], location)
    } else {
      setShowResultPopup(true)
    }
  }

  const moveToPrevCandidate = () => {
    if (candidateIds.length && currentCandidateIndex > 0) {
      navigateToCVScreening(candidateIds[currentCandidateIndex - 1], location)
    }
  }

  const onGenerateScorecard = async (result: InterviewResult) => {
    if (!candidate?.active_interview_round?.latest_interview_stage?.id) {
      return
    }

    setPendingActions(true)
    try {
      await generateAndPatchResultScorecard(
        candidate.active_interview_round.latest_interview_stage.id,
        user.id,
        result,
      )
    } catch (e) {
      Sentry.captureException('[CV screening]: cannot submit a feedback')
      toast.show(
        <Toast>
          <Toast.Indicator>
            <Icon name="CrossCircle" color={Token.color.red} />
          </Toast.Indicator>
          <Toast.Label>Cannot submit a feedback</Toast.Label>
        </Toast>,
        'long',
      )
    } finally {
      setPendingActions(false)
    }
  }

  const moveToNextStage = async () => {
    if (!round?.id || !nextStage?.id) {
      return
    }

    setPendingActions(true)

    try {
      const response = await changeInterviewStage(round.id, nextStage.id)
      if (response.data.latest_interview_stage?.title) {
        showSuccessToast(`changed stage to ${response.data.latest_interview_stage.title}`)
      }
    } catch (e) {
      const errorMsg = getMessageFromError(e)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Cannot change the interview stage</StatusPopup.Title>
          {errorMsg && <StatusPopup.Description>{errorMsg}</StatusPopup.Description>}
        </StatusPopup>,
      )
      Sentry.captureException(e)
    } finally {
      setPendingActions(false)
    }
  }

  const onClickSkip = async () => {
    if (candidate?.id) {
      setCVScreeningSession({
        skippedCandidateIds: [...(session.skippedCandidateIds || []), candidate.id],
      })
    }

    if (!round?.id || !currentStageId) {
      await moveToNextCandidate()
      showSuccessToast('candidate skipped')
      return
    }

    // do not need to wait, it's fine just to go to the next candidate. If it fails - it fails, nothing critical
    skipCVScreening(round!.id, currentStageId!)
    showSuccessToast('candidate skipped')
    moveToNextCandidate()
  }

  const onClickQuickArchive = async () => {
    if (!candidate || !round) {
      return
    }

    const rememberedArchivingData = await getUpdatedArchivingData(candidate.id)

    const emailDataValid =
      rememberedArchivingData?.email_body &&
      rememberedArchivingData?.email_body !== '<p><br></p>' &&
      rememberedArchivingData?.email_template &&
      rememberedArchivingData?.sender_type &&
      rememberedArchivingData?.subject &&
      rememberedArchivingData?.when_to_send &&
      !hasEmailNonPrefilledPlaceholders(rememberedArchivingData?.email_body) &&
      !hasEmailNonPrefilledPlaceholders(rememberedArchivingData?.subject)

    if (
      !rememberedArchivingData ||
      (rememberedArchivingData.sendEmail && !emailDataValid)
    ) {
      setAction({
        type: 'archive',
      })
      return
    }

    setPendingActions(true)

    try {
      await archiveInterviews(
        candidate.id,
        [round.id],
        rememberedArchivingData.reason!,
        rememberedArchivingData.comments,
      )

      if (!rememberedArchivingData.sendEmail) {
        showSuccessToast('candidate archived')
        if (candidate?.id) {
          setCVScreeningSession({
            archivedCandidateIds: [...(session.archivedCandidateIds || []), candidate.id],
          })
        }
        moveToNextCandidate()
        return
      }

      try {
        await sendCandidateEmail(candidate.id, rememberedArchivingData)

        showSuccessToast('candidate archived and email sent')
        if (candidate?.id) {
          setCVScreeningSession({
            archivedCandidateIds: [...(session.archivedCandidateIds || []), candidate.id],
          })
        }
        moveToNextCandidate()
      } catch (e) {
        const errorMsg = getMessageFromError(e)

        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>
              Candidate archived but email has not been sent
            </StatusPopup.Title>
            {errorMsg && <StatusPopup.Description>{errorMsg}</StatusPopup.Description>}
          </StatusPopup>,
        )
        Sentry.captureException(e)
      } finally {
        setPendingActions(false)
      }
    } catch (e) {
      const errorMsg = getMessageFromError(e)

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Cannot archive the candidate</StatusPopup.Title>
          {errorMsg && <StatusPopup.Description>{errorMsg}</StatusPopup.Description>}
        </StatusPopup>,
      )
      Sentry.captureException(e)
    } finally {
      setPendingActions(false)
    }
  }

  const onClickSchedule = () => {
    if (schedulingDisabled || pendingActions) {
      return
    }

    if (
      nextStage &&
      InterviewShowScheduleSidebarStatuses.includes(nextStage.scheduling_status)
    ) {
      setAction({
        type: 'schedule',
        mode: 'view',
      })
    } else {
      setAction({
        type: 'schedule',
        mode: 'scheduling',
      })
    }
  }

  const onClickApplicationQuestions = () => {
    if (
      !applicationFormSections.length ||
      pendingActions ||
      isApplicationFormSectionsLoading
    ) {
      return
    }

    setAction({
      type: 'applicationQuestions',
    })
  }

  const onClickTimeline = () => {
    if (pendingActions) {
      return
    }

    setAction({
      type: 'timeline',
    })
  }

  const onClickSendTest = () => {
    if (
      nextStage?.interview_type !== 'online_test' ||
      sendOnlineTestDisabled ||
      pendingActions
    ) {
      return
    }

    setAction({
      type: 'sendOnlineTest',
    })
  }

  const onClickChangeStage = () => {
    setAction({
      type: 'changeStage',
    })
  }

  const onClickAssignDifferentRecruiter = () => {
    setAction({
      type: 'assignDifferentRecruiter',
    })
  }

  const onClickAssignDifferentJobPosting = () => {
    setAction({
      type: 'assignDifferentJobPosting',
    })
  }

  const onClickArchive = () => {
    setAction({
      type: 'archive',
    })
  }

  const onClickMoveNextStage = async () => {
    if (candidate?.id) {
      setCVScreeningSession({
        movedToNextStageCandidateIds: [
          ...(session.movedToNextStageCandidateIds || []),
          candidate.id,
        ],
      })
    }
    await moveToNextStage()
    await onGenerateScorecard(InterviewResult.Hire)
    await moveToNextCandidate()
  }

  const handleKeyDown = async (event: KeyboardEvent) => {
    switch (event.ctrlKey && event.altKey && event.code) {
      case KeyboardShortcuts.quickArchive.key: {
        event.preventDefault()
        onClickQuickArchive()
        break
      }
      case KeyboardShortcuts.skip.key: {
        event.preventDefault()
        await onClickSkip()
        break
      }
      case KeyboardShortcuts.moveNextStage.key: {
        event.preventDefault()
        await onClickMoveNextStage()
        break
      }
      case KeyboardShortcuts.linkedin.key: {
        event.preventDefault()
        if (linkedinUrl) {
          window.open(linkedinUrl, '_blank')
        }
        break
      }
      case KeyboardShortcuts.schedule.key: {
        event.preventDefault()
        onClickSchedule()
        break
      }
      case KeyboardShortcuts.applicationQuestions.key: {
        event.preventDefault()
        onClickApplicationQuestions()
        break
      }
      case KeyboardShortcuts.timeline.key: {
        event.preventDefault()
        onClickTimeline()
        break
      }
      case KeyboardShortcuts.sendOnlineTest.key: {
        event.preventDefault()
        onClickSendTest()
        break
      }
      case KeyboardShortcuts.changeStage.key: {
        event.preventDefault()
        onClickChangeStage()
        break
      }
      case KeyboardShortcuts.assignDifferentRecruiter.key: {
        event.preventDefault()
        onClickAssignDifferentRecruiter()
        break
      }
      case KeyboardShortcuts.assignDifferentJobPosting.key: {
        event.preventDefault()
        onClickAssignDifferentJobPosting()
        break
      }
      case KeyboardShortcuts.archiveWithDifferentReason.key: {
        event.preventDefault()
        onClickArchive()
        break
      }
      default:
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [round, currentStageId, candidate])

  const onArchiveWithDifferentReasonSuccess = async () => {
    if (candidate?.id) {
      setCVScreeningSession({
        archivedCandidateIds: [...(session.archivedCandidateIds || []), candidate.id],
      })
    }
    setAction(undefined)
    await onGenerateScorecard(InterviewResult.NoHire)

    showSuccessToast('candidate archived')
    await moveToNextCandidate()
  }

  const onScheduleSuccess = async () => {
    setAction(undefined)
    await moveToNextStage()
    moveToNextCandidate()
  }

  const onSendOnlineTestSuccess = async () => {
    setAction(undefined)
    await moveToNextStage()
    moveToNextCandidate()
  }

  const onChangeStageSuccess = (updatedRound: InterviewRoundInterface) => {
    setAction(undefined)
    showSuccessToast(
      `stage changed${
        updatedRound.latest_interview_stage
          ? ` to ${updatedRound.latest_interview_stage.title}`
          : ''
      }`,
    )
    moveToNextCandidate()
  }

  const onChangeRecruiterSuccess = (recruiter: InterviewRecruiterInterface) => {
    setAction(undefined)
    showSuccessToast(`recruiter changed to ${recruiter.display_name}`)
    refresh()
  }

  const onChangeJobPostingSuccess = (jobPosting: IdAndName, main: boolean) => {
    setAction(undefined)
    showSuccessToast(
      main
        ? `main job posting changed to ${jobPosting.name}`
        : `added job posting ${jobPosting.name}`,
    )
  }

  const renderError = () => {
    if (error) {
      return (
        <CVScreeningError
          title="You cannot screen this candidate"
          text={error}
          onSkip={moveToNextCandidate}
        />
      )
    }

    if (round?.latest_interview_stage?.interview_type !== 'cv_screening') {
      return (
        <CVScreeningError
          title="You cannot screen this candidate"
          text="The current interview stage is not the CV Screening"
          onSkip={moveToNextCandidate}
        />
      )
    }

    if (round?.archived_reason) {
      return (
        <CVScreeningError
          title="You cannot screen this candidate"
          text="The candidate has been archived"
          onSkip={moveToNextCandidate}
        />
      )
    }

    return null
  }

  const renderActions = () => {
    switch (action?.type) {
      case 'archive': {
        if (!candidate) {
          return null
        }

        return (
          <CVScreeningArchive
            candidate={candidate}
            onLoading={setPendingActions}
            onSuccess={onArchiveWithDifferentReasonSuccess}
            onClose={() => setAction(undefined)}
          />
        )
      }

      case 'applicationQuestions': {
        if (!candidate) {
          return null
        }

        return (
          <CVScreeningApplicationQuestions
            candidateId={candidate.id}
            onClose={() => setAction(undefined)}
          />
        )
      }

      case 'schedule': {
        if (schedulingDisabled) {
          return null
        }

        return (
          <ScheduleSidebar
            selectedStageId={nextStage?.id}
            stages={stages}
            totalStagesCount={stages.length}
            roundId={round?.id}
            onClose={() => setAction(undefined)}
            initialMode={action.mode}
            onSchedulingSuccess={onScheduleSuccess}
            onChangeInitialMode={mode => {
              setAction(
                prev =>
                  ({
                    ...prev,
                    mode,
                  } as ActionInterface),
              )
            }}
          />
        )
      }

      case 'sendOnlineTest': {
        if (sendOnlineTestDisabled) {
          return null
        }

        return (
          <CVScreeningSendOnlineTest
            onClose={() => setAction(undefined)}
            nextStageId={nextStage.id}
            candidateId={candidate.id}
            onSuccess={onSendOnlineTestSuccess}
            roundId={round?.id}
          />
        )
      }

      case 'timeline':
        return (
          <CVScreeningTimeline roundId={round?.id} onClose={() => setAction(undefined)} />
        )

      case 'changeStage': {
        if (!round) {
          return null
        }

        return (
          <ChangeStagePopup
            open
            onClose={() => setAction(undefined)}
            data={stages}
            roundId={round.id}
            onChange={onChangeStageSuccess}
            currentStageId={currentStageId}
            noSuccessPopup
          />
        )
      }

      case 'assignDifferentRecruiter': {
        if (!round) {
          return null
        }

        return (
          <CVScreeningChangeRecruiter
            onClose={() => setAction(undefined)}
            round={round}
            onSuccess={onChangeRecruiterSuccess}
          />
        )
      }

      case 'assignDifferentJobPosting': {
        if (!candidate) {
          return null
        }

        return (
          <CVScreeningChangeJobPosting
            candidateId={candidate.id}
            onClose={() => setAction(undefined)}
            onSuccess={onChangeJobPostingSuccess}
            onRefresh={refresh}
          />
        )
      }

      default:
        return null
    }
  }

  return (
    <PageWrapper maxWidth={1278}>
      {showResultPopup && <CvScreeningResultPopup />}
      <CVScreeningHeader
        currentNumber={currentCandidateIndex === -1 ? 1 : currentCandidateIndex + 1}
        total={candidateIds.length || 1}
        onNext={moveToNextCandidate}
        onPrev={moveToPrevCandidate}
        disablePrev={!candidateIds.length || currentCandidateIndex === 0}
      >
        {loading ? (
          <MoreBarSkeleton />
        ) : (
          <>
            {!screeningError && (
              <>
                <HStack gap="s-8">
                  <Bar>
                    <ActionButton
                      useIcon="Archive"
                      variant="negative"
                      onClick={onClickQuickArchive}
                      disabled={pendingActions}
                      {...tooltips.quickArchive.anchorProps}
                    >
                      Archive
                    </ActionButton>
                    {tooltips.quickArchive.tooltip}
                    <ActionButton
                      useIcon="TurboTransfer"
                      onClick={onClickSkip}
                      disabled={pendingActions}
                      {...tooltips.skip.anchorProps}
                    >
                      Skip
                    </ActionButton>
                    {tooltips.skip.tooltip}
                    <ActionButton
                      useIcon="SocialLike"
                      onClick={onClickMoveNextStage}
                      disabled={pendingActions}
                      {...tooltips.nextStage.anchorProps}
                    >
                      Move to next stage
                    </ActionButton>
                    {tooltips.nextStage.tooltip}
                  </Bar>
                  <Box>
                    <Separator orientation="vertical" />
                  </Box>
                  <Bar>
                    <ActionButton
                      useIcon="LogoLinkedIn"
                      iconOnly
                      use="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={linkedinUrl}
                      disabled={!linkedinUrl || pendingActions}
                      {...tooltips.linkedin.anchorProps}
                    />
                    {tooltips.linkedin.tooltip}

                    <ActionButton
                      useIcon="CalendarDate"
                      iconOnly
                      aria-disabled={pendingActions}
                      onClick={onClickSchedule}
                      {...tooltips.scheduling.anchorProps}
                    />
                    {tooltips.scheduling.tooltip}

                    <ActionButton
                      useIcon="Questionnaire"
                      iconOnly
                      aria-disabled={
                        !applicationFormSections.length ||
                        pendingActions ||
                        isApplicationFormSectionsLoading
                      }
                      onClick={onClickApplicationQuestions}
                      pending={isApplicationFormSectionsLoading}
                      {...tooltips.applicationQuestions.anchorProps}
                    />
                    {tooltips.applicationQuestions.tooltip}

                    <ActionButton
                      useIcon="History"
                      iconOnly
                      onClick={onClickTimeline}
                      aria-disabled={pendingActions}
                      {...tooltips.timeline.anchorProps}
                    />
                    {tooltips.timeline.tooltip}

                    <ActionButton
                      useIcon="Ellipsis"
                      iconOnly
                      {...dropdown.getAnchorProps()}
                    />
                  </Bar>
                </HStack>
                <ActionMenu
                  {...dropdown.getTargetProps()}
                  focusTrap={false}
                  minWidth={350}
                >
                  <ActionMenu.Group>
                    {hiringProcessSettings?.enable_online_test_stage && (
                      <>
                        <ActionMenu.Item
                          useIcon="Envelope"
                          aria-disabled={
                            nextStage?.interview_type !== 'online_test' ||
                            sendOnlineTestDisabled ||
                            pendingActions
                          }
                          onClick={onClickSendTest}
                          {...tooltips.sendOnlineTest.anchorProps}
                        >
                          <ActionMenu.Title>
                            <Flex gap="s-8" justifyContent="space-between">
                              <Text>Send online test</Text>
                              <Text variant="small" color="grey-tone-20">
                                {KeyboardShortcuts.sendOnlineTest.label}
                              </Text>
                            </Flex>
                          </ActionMenu.Title>
                          <ActionMenu.Description>
                            Choose and send online test from the current hiring process
                          </ActionMenu.Description>
                        </ActionMenu.Item>
                        {tooltips.sendOnlineTest.tooltip}
                      </>
                    )}

                    <ActionMenu.Item
                      useIcon="ArrowBulk"
                      disabled={pendingActions}
                      onClick={onClickChangeStage}
                    >
                      <ActionMenu.Title>
                        <Flex gap="s-8" justifyContent="space-between">
                          <Text>Change stage</Text>
                          <Text variant="small" color="grey-tone-20">
                            {KeyboardShortcuts.changeStage.label}
                          </Text>
                        </Flex>
                      </ActionMenu.Title>
                      <ActionMenu.Description>
                        Move candidate to any stage in the current hiring process
                      </ActionMenu.Description>
                    </ActionMenu.Item>

                    <ActionMenu.Item
                      useIcon="Profile"
                      disabled={pendingActions}
                      onClick={onClickAssignDifferentRecruiter}
                    >
                      <ActionMenu.Title>
                        <Flex gap="s-8" justifyContent="space-between">
                          <Text>Assign to different recruiter</Text>
                          <Text variant="small" color="grey-tone-20">
                            {KeyboardShortcuts.assignDifferentRecruiter.label}
                          </Text>
                        </Flex>
                      </ActionMenu.Title>
                      <ActionMenu.Description>
                        Choose another recruiter to manage this candidate
                      </ActionMenu.Description>
                    </ActionMenu.Item>

                    <ActionMenu.Item
                      onClick={onClickAssignDifferentJobPosting}
                      useIcon="Services"
                      disabled={pendingActions}
                    >
                      <ActionMenu.Title>
                        <Flex gap="s-8" justifyContent="space-between">
                          <Text>Assign to different Job Posting</Text>
                          <Text variant="small" color="grey-tone-20">
                            {KeyboardShortcuts.assignDifferentJobPosting.label}
                          </Text>
                        </Flex>
                      </ActionMenu.Title>
                      <ActionMenu.Description>
                        Choose another main job posting for this candidate
                      </ActionMenu.Description>
                    </ActionMenu.Item>

                    <Separator />

                    <ActionMenu.Item
                      useIcon="Archive"
                      color={Token.color.red}
                      disabled={pendingActions}
                      onClick={onClickArchive}
                    >
                      <ActionMenu.Title>
                        <Flex gap="s-8" justifyContent="space-between">
                          <Text>Archive with different reason</Text>
                          <Text variant="small" color="grey-tone-20">
                            {KeyboardShortcuts.archiveWithDifferentReason.label}
                          </Text>
                        </Flex>
                      </ActionMenu.Title>
                      <ActionMenu.Description>
                        Archive candidate with a custom email and archival reason
                      </ActionMenu.Description>
                    </ActionMenu.Item>
                  </ActionMenu.Group>
                </ActionMenu>
              </>
            )}
          </>
        )}
      </CVScreeningHeader>

      <Box mt="s-16" height="100%">
        {screeningError ? (
          <PageBody>{renderError()}</PageBody>
        ) : (
          <TwoColumnsLayout
            left={
              <>
                {/* rendering everything here and hide it to avoid fetching data/rendering again. Speed is crucial here */}
                <Relative height="calc(100vh - 185px)">
                  {!candidates.size && <DocumentSkeleton maxWidth={940} />}
                  {[...candidates.keys()].map(id => (
                    <Absolute
                      top={0}
                      width="100%"
                      height="100%"
                      key={id}
                      opacity={+id === +params.id ? '1' : '0'}
                      zIndex={+id === +params.id ? 1 : -1}
                    >
                      <CVPreview id={+id} loading={!candidates.get(id)?.loaded} />
                    </Absolute>
                  ))}
                </Relative>
              </>
            }
            rightMaxWidth={400}
            right={
              <>
                {candidate && round && (
                  <CVScreeningSide candidate={candidate} round={round} />
                )}
              </>
            }
          />
        )}
      </Box>

      {renderActions()}
    </PageWrapper>
  )
}

export default CvScreening
