import React, { useState } from 'react'
import Form from '@src/features/Form/Form'
import General from '@src/pages/Forms/JobPosting/General/General'
import Preview from '@src/pages/Forms/JobPosting/Preview/Preview'
import ApplicationForm from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationForm'
import Automation from '@src/pages/Forms/JobPosting/Automation/Automation'
import { JobDescriptionFormRequest } from '@src/api/jobPosting'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { BREAKPOINTS, Box, Flex, Icon } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Route, Switch, useParams } from 'react-router-dom'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetJobPostingSettings, useGlobalSettings } from '@src/api/settings'
import BookingLinksTable from '@src/features/BookingLinks/Recruiter/BookingLinksTable'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import CommonRequisitionTable from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { Subtitle, SubtitleLink } from '@src/components/Page/Header/PageHeaderTitle'
import StatusTag from '@src/pages/Forms/JobPosting/Components/StatusTag'
import TextWithMoreCount from '@src/components/TextWithMoreCount/TextWithMoreCount'
import {
  useGetOpenRequisitionsByJobPosting,
  useGetPendingRequisitionsByJobPosting,
} from '@src/api/requisitions'
import JobPostingActions from './Components/JobPostingActions'

const JobDescriptionTabs = connect(() => {
  const form = useLapeContext<JobPostingInterface>()
  const { values } = form

  const [isApproving, setIsApproving] = useState(false)

  const params = useParams<{ id: string; specId: string }>()

  const backUrl = params.specId
    ? pathToUrl(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, { id: params.specId })
    : pathToUrl(ROUTES.RECRUITMENT.JOB_POSTINGS)

  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const { settings: globalSettings } = useGlobalSettings()
  const { data: openRequisitions, isLoading: loadingOpenRequisitions } =
    useGetOpenRequisitionsByJobPosting(values.id)
  const { data: pendingRequisitions, isLoading: loadingPendingRequisitions } =
    useGetPendingRequisitionsByJobPosting(values.id)
  const openRequisitionCount = openRequisitions?.count ?? 0
  const pendingRequisitionCount = pendingRequisitions?.count ?? 0

  const permissions = useSelector(selectPermissions)
  const canViewBookingLinkDefinition =
    globalSettings?.candidates_scheduling_enabled &&
    permissions.includes(PermissionTypes.ViewBookingLinkDefinition)

  const tabs = [
    {
      title: 'Overview',
      path: ROUTES.FORMS.JOB_POSTING.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, params),
      component: Preview,
      canView: !!values?.id,
      icon: <Icon name="InfoOutline" size={15} />,
    },
    {
      title: 'Automation workflow',
      path: ROUTES.FORMS.JOB_POSTING.AUTOMATION,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.AUTOMATION, params),
      component: Automation,
      canView:
        !!values?.id &&
        jobPostingSettings?.enable_automation_rules &&
        permissions.includes(PermissionTypes.ViewApplicationautomationrule),
    },
    {
      title: 'Booking links',
      path: ROUTES.FORMS.JOB_POSTING.BOOKING_LINKS,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.BOOKING_LINKS, params),
      component: () => <BookingLinksTable jobPosting={values} />,
      canView: !!values?.id && canViewBookingLinkDefinition,
      icon: <Icon name="20/Linked" size={15} />,
    },
    {
      title: 'Requisitions',
      path: ROUTES.FORMS.JOB_POSTING.REQUISITIONS,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING.REQUISITIONS, params),
      component: () => (
        <CommonRequisitionTable
          type="jobPosting"
          sortBy={[
            {
              sortBy: 'status',
              direction: SORT_DIRECTION.ASC,
            },
          ]}
          filterBy={[
            {
              filters: initialRequisitionStatusFilter,
              columnName: 'status',
              nonResettable: true,
            },
            {
              columnName: 'requisition_posting__job_posting',
              filters: [{ id: values.id, name: String(values.name) }],
              nonResettable: true,
            },
          ]}
        />
      ),
      canView: !!values?.id,
      icon: <Icon name="AddContact" size={15} />,
    },
  ]

  const filteredTabs = tabs.filter(tab =>
    tab.canView === undefined ? true : tab.canView,
  )

  const locationNames = (values.locations ?? []).map(({ location_name }) => location_name)

  return (
    <PageWrapper>
      <PageHeader
        title={
          <Box maxWidth={BREAKPOINTS.xl}>
            <PageHeader.Title
              actions={
                <JobPostingActions
                  onApproving={setIsApproving}
                  openRequisitionCount={openRequisitionCount}
                />
              }
              labels={
                <>
                  <StatusTag status={values.status} />
                  {values.recruiter && (
                    <SubtitleLink
                      iconName="Profile"
                      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, {
                        id: values.recruiter?.id,
                      })}
                    >
                      {values.recruiter?.name}
                    </SubtitleLink>
                  )}
                  {!!locationNames.length && (
                    <Subtitle iconName="LocationPin">
                      <TextWithMoreCount
                        expandable
                        hideSuffix
                        items={locationNames}
                        visibleCount={3}
                      />
                    </Subtitle>
                  )}
                </>
              }
              title={values.name}
            />
          </Box>
        }
        backUrl={backUrl}
      >
        <Box pb="s-8" maxWidth={BREAKPOINTS.xl}>
          <TabBarNavigation tabs={filteredTabs} />
        </Box>
      </PageHeader>
      <Flex
        pt="s-8"
        flex="1 0"
        flexDirection="column"
        width="100%"
        maxWidth={BREAKPOINTS.xl}
      >
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component
                isApproving={isApproving}
                openRequisitionCount={openRequisitionCount}
                pendingRequisitionCount={pendingRequisitionCount}
                loading={loadingOpenRequisitions || loadingPendingRequisitions}
              />
            </Route>
          ))}
        </Switch>
      </Flex>
    </PageWrapper>
  )
})

const JobDescriptionPage = () => {
  return (
    <Form api={JobDescriptionFormRequest} validator={{}}>
      <Switch>
        <Route exact path={ROUTES.FORMS.JOB_POSTING.GENERAL}>
          <General />
        </Route>
        <Route exact path={ROUTES.FORMS.JOB_POSTING.APPLICATION_FORM}>
          <ApplicationForm />
        </Route>
        <JobDescriptionTabs />
      </Switch>
    </Form>
  )
}

export default connect(JobDescriptionPage)
