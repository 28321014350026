import React from 'react'
import {
  ActionButton,
  Avatar,
  DetailsCell,
  Group,
  Item,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { InterviewRoundState } from '@src/interfaces/interviewTool'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import useCandidatePipelineData from '@src/pages/Forms/JobPosting/Components/useCandidatePipelineData'

const CandidatesPipelineOverview = () => {
  const { hiringPipelineId, stats, loading } = useCandidatePipelineData([
    {
      columnName: 'active_interview_round__state',
      filters: [
        { id: InterviewRoundState.active, name: InterviewRoundState.active },
        { id: InterviewRoundState.archived, name: InterviewRoundState.archived },
        { id: InterviewRoundState.hired, name: InterviewRoundState.hired },
      ],
    },
  ])
  if (loading) {
    return (
      <Group>
        <ItemSkeleton />
      </Group>
    )
  }
  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="DataLimit" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Candidates</Item.Title>
        </Item.Content>
        {hiringPipelineId && (
          <Item.Side>
            <ActionButton
              useIcon="16/LinkExternal"
              use={InternalLink}
              target="_blank"
              to={pathToUrl(ROUTES.FORMS.HIRING_PIPELINE_OVERVIEW.CANDIDATES, {
                id: hiringPipelineId,
              })}
            >
              View Pipeline
            </ActionButton>
          </Item.Side>
        )}
      </Item>
      {stats?.map(({ label, count }) => (
        <DetailsCell key={label} variant={label === 'Total' ? 'header' : undefined}>
          <DetailsCell.Title>{label}</DetailsCell.Title>
          <DetailsCell.Content>{count}</DetailsCell.Content>
        </DetailsCell>
      ))}
    </Group>
  )
}

export default CandidatesPipelineOverview
