import React, { useEffect, useState } from 'react'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { Statuses } from '@src/interfaces'
import { getRequisitionRecruiter } from '@src/api/recruitmentGroups'
import {
  getRequisitionsCommentsAPI,
  useGetRequisitionApprovals,
} from '@src/api/requisitions'
import { Avatar, Box, Group, InputGroup, Item, MoreBar, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import ReapprovalBanner from '@src/features/ApprovalFlow/ReapprovalBanner'
import { EntityPermissions } from '@src/store/auth/types'
import CommentsSection from '@src/features/Comments/CommentsSection'
import RequisitionCompensationBand from '@src/components/LocationCompensationBand/RequisitionCompensationBand'
import { useGetRequisitionCompensationBands } from '@src/api/benchmarks'
import MrtWidget from '@src/pages/Forms/RequisitionForm/Mrt/MrtWidget'
import BudgetImpact from '@src/pages/Forms/RequisitionForm/General/BudgetImpact'
import { useGetRequisitionSettings, useGlobalSettings } from '@src/api/settings'
import { parseLegacyApprovalSteps } from '@src/utils/approvalFlow'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import JobPostingWidget from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget'
import ApprovalFlowWidget from '@src/features/ApprovalFlow/ApprovalFlowWidget'
import RequisitionDetails from '@src/pages/Forms/RequisitionForm/Preview/RequistionDetails'

type PreviewProps = {
  isApproving?: boolean
}

const Preview = ({ isApproving = false }: PreviewProps) => {
  const form = useLapeContext<RequisitionInterface>()

  const { values, dirty } = form

  const {
    settings: { job_postings_enabled },
  } = useGlobalSettings()

  const { data: requisitionSettings } = useGetRequisitionSettings()

  const { data: approvalSteps, isRefetching: isApprovalLoading } =
    useGetRequisitionApprovals(
      requisitionSettings?.enable_approvals ? values.id : undefined,
    )

  const [showPendingChanges, setShowPendingChanges] = useState(true)

  const { data: compensationBands } = useGetRequisitionCompensationBands(
    requisitionSettings?.enable_budget_impact ? values.id : null,
    values.locations
      ?.filter(item => item.posting_compensation_enabled)
      ?.map(item => item.id),
  )

  useEffect(() => {
    // in General component (Settings tab) we replace form.values with previous changelog values and if we switched tab, the data on Email tab would be outdated
    if (form.initialValues) {
      form.reset(form.initialValues as RequisitionInterface)
    }
  }, [])

  const canViewComments = values.field_options?.actions?.includes(
    EntityPermissions.AccessComments,
  )

  useEffect(() => {
    if (
      !dirty ||
      !values.specialisation?.id ||
      !values.seniority_max?.id ||
      !values.main_location?.id
    ) {
      return
    }

    updateDefaultRecruiter()
  }, [values.specialisation?.id, values.seniority_max?.id, values.main_location?.id])

  if (!values.id) {
    return <InternalRedirect to={pathToUrl(ROUTES.FORMS.REQUISITION.SETTINGS, {})} />
  }

  const updateDefaultRecruiter = () => {
    if (values.main_location) {
      getRequisitionRecruiter(
        +values.specialisation.id,
        values.seniority_max!.id,
        values.main_location.id,
      ).then(recruiter => {
        if (recruiter) {
          values.recruiter = recruiter
        }
      })
    }
  }

  const isReapproval = values.is_published && values.status === Statuses.pending

  return (
    <VStack gap="s-16" mt="s-8">
      {values.id && requisitionSettings?.enable_approvals && (
        <>
          {isReapproval && (
            <ReapprovalBanner
              dataType="Requisition"
              actions={
                <MoreBar>
                  <MoreBar.Action
                    useIcon={showPendingChanges ? 'SwitchOn' : 'SwitchOff'}
                    onClick={() => setShowPendingChanges(!showPendingChanges)}
                  >
                    Show pending changes
                  </MoreBar.Action>
                </MoreBar>
              }
            />
          )}
        </>
      )}
      <TwoColumnsLayout
        left={
          <>
            {requisitionSettings?.enable_budget_impact && (
              <RequisitionCompensationBand bands={compensationBands} />
            )}
            {requisitionSettings?.enable_is_mrt_jira_ticket_url_confirmed_editing && (
              <MrtWidget />
            )}
            <RequisitionDetails showPendingChanges={showPendingChanges} />
            {requisitionSettings?.enable_budget_impact && (
              <Group>
                <Item>
                  <Item.Avatar>
                    <Avatar useIcon="CreditBag" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>Budget impact</Item.Title>
                  </Item.Content>
                </Item>
                <BudgetImpact />
              </Group>
            )}
            {canViewComments && (
              <Box pt="s-16">
                <InputGroup>
                  <CommentsSection api={getRequisitionsCommentsAPI(values.id)} />
                </InputGroup>
              </Box>
            )}
          </>
        }
        right={
          <>
            {values.id && requisitionSettings?.enable_approvals && (
              <ApprovalFlowWidget
                isLoading={isApprovalLoading || isApproving}
                steps={approvalSteps ? parseLegacyApprovalSteps(approvalSteps) : null}
              />
            )}
            {job_postings_enabled && <JobPostingWidget />}
          </>
        }
      />
    </VStack>
  )
}

export default Preview
