import React from 'react'
import { Flex, Link, Tag, Text, Token } from '@revolut/ui-kit'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { StatusTag, SubtitleLink } from '@src/components/Page/Header/PageHeaderTitle'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { Statuses } from '@src/interfaces'

type RequisitionFormTitleProps = {
  actions: React.ReactNode
  data: RequisitionInterface
}

const getApprovalStatusIconName = (status?: Statuses | ApprovalStatuses) => {
  switch (status) {
    case Statuses.pending:
      return '16/SandWatch'
    case Statuses.opened:
      return '16/Live'
    default:
      return undefined
  }
}

export const RequisitionFormTitle = ({ actions, data }: RequisitionFormTitleProps) => {
  const currentStatus = data.status
  return (
    <PageHeader.Title
      actions={actions}
      useIcon="AddContact"
      labels={
        <Flex alignItems="center" gap="s-8">
          <StatusTag
            useIcon={getApprovalStatusIconName(currentStatus)}
            status={currentStatus}
          />
          {data.is_confidential && (
            <Tag variant="outlined" color={Token.color.orange}>
              Confidential
            </Tag>
          )}
          <SubtitleLink
            iconName="RepairTool"
            to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
              id: data.specialisation.id,
            })}
          >
            {data.specialisation.name}
          </SubtitleLink>
          <SubtitleLink
            iconName="People"
            to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, {
              id: data.team.id,
            })}
          >
            {data.team.name}
          </SubtitleLink>
        </Flex>
      }
      title={data.requisition_title}
    />
  )
}

type RequisitionFormSubtitleProps = {
  data: RequisitionInterface
}

export const RequisitionFormSubtitle = ({ data }: RequisitionFormSubtitleProps) => {
  return (
    <>
      {data.specialisation?.id && (
        <Link
          href={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
            id: data.specialisation.id,
          })}
          target="_blank"
          color="inherit"
        >
          {data.specialisation.name}
        </Link>
      )}
      {!!data.specialisation?.id && !!data.team?.name && (
        <Text color="grey-20"> at </Text>
      )}
      {data.team?.name && (
        <Link
          to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: data.team.id })}
          use={InternalLink}
          target="_blank"
          color="inherit"
        >
          {data.team.name}
        </Link>
      )}
    </>
  )
}
